import React from "react"

import ITSolutions from "../components/Solutions/ITSolutions"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"

export default function(params) {
  return (
    <Layout>
      <InnerPageBanner headingText={"IT Solutions"} linkText={"IT Solutions"} />
      <div style={{ minHeight: "100%" }}>
        <div
          className="force-white-text"
          style={{ textAlign: "center", minHeight: "100%", width: "100%" }}
        >
          <ITSolutions mode={`white`} />
        </div>
      </div>
    </Layout>
  )
}
