import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{textAlign: "left", background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
            <p>
            Sheer Analytics and Insights is a global IT solutions provider that offers a wide range of services in IT
solutions covering FTE in Javascript, Python, Node JS, and other technologies, website designing, and
software designing and coding. In today&#39;s competitive digital landscape, businesses need to keep up
with the latest technologies and online marketing strategies to stay ahead of the competition. Sheer
Analytics and Insights provides comprehensive IT solutions to help businesses achieve their goals.{" "}
             
            </p>
            <h1>Full-Time Equivalent (FTE)</h1>
            <p>
            Full-time equivalent (FTE) in Javascript, Python, and Node JS are highly sought after skills in the IT
industry. Sheer Analytics and Insights provides businesses with dedicated resources for these
technologies, allowing them to focus on developing cutting-edge software applications. The FTE
model provides a cost-effective solution for businesses looking to expand their IT capabilities
without hiring additional staff.
            </p>
            <h1>Website Designing</h1>
            <p>Website designing is a critical aspect of any business&#39;s online presence. Sheer Analytics and Insights
provides website design services that help businesses create attractive and functional websites that
are easy to navigate. Their website designs are optimized for all devices and platforms, ensuring that
customers can access the website from any device. This helps businesses attract more visitors and
increase their online visibility.</p>
            <h1>Software Designing and Coding</h1>
            <p>Software designing and coding are essential for businesses that want to create custom software
applications. Sheer Analytics and Insights provides software designing and coding services that help
businesses create applications tailored to their specific needs. Their software solutions are designed
to be user-friendly, scalable, and secure. This helps businesses streamline their operations and
improve efficiency.<br/><br/>
For any queries reach us at <a href="mailto:itsolutions@sheeranalyticsandinsights.com">itsolutions@sheeranalyticsandinsights.com</a></p>
          </div>
        </div>
    </section>
  )
}
